import request from '@/utils/request'

//获取门户公告列表
export function getNoticeList(params) {
    return request({
      url: '/portal/public-notice/get-list',
      method: 'GET',
      params
    })
  }

  //获取门户首页公告
export function getNoticeIndexList(params) {
    return request({
      url: '/portal/public-notice/get-index-list',
      method: 'GET',
      params
    })
  }

    //通知公告详情
export function getNoticeInfo(params) {
    return request({
      url: '/portal/public-notice/get-info',
      method: 'GET',
      params
    })
  }

 

 