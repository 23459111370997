<template>
  <!-- 通知公告 -->
  <div >
    <div class="head-img" v-show="noticeFlag" ><img src="@/assets/notice.png"></div>
  <div class="announcement">
    <div class="contain" >
      <!-- <Mainpage></Mainpage> -->
      <router-view ></router-view>
    </div>
  </div>
  </div>
</template>

<script>
import Mainpage from "@/views/announcement/components/mainpage.vue";
import {mapState} from "vuex"
export default {
  name: "Announcement",
  components: {
    Mainpage,
  },
  data() {
    return {
      
    };
  },
  computed:{
...mapState(["noticeFlag"])
  },
  methods: {},

};
</script>
<style scoped lang="scss">
  
  .head-img{
    width: 100%;
    img{
      display: block;
      width: 100%;
    }
  }
.announcement {
  width: 100%;
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  .contain {
    // width: calc(100% - 120px);
    width: 1200px;
  }
}



</style>
