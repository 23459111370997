<template>
  <div class="mainpage">
     <!-- <div class="head-img"><img src="@/assets/notice.png"></div> -->
    <div
      class="item"
      v-for="(item, index) in noticelist"
      :key="index"
      @click="todetail(item.id)"
    >
      <div class="update-time">
        <div>{{ item.updated_at.substring(8, 10) }}</div>
        <div>{{ item.updated_at.substring(7, 0) }}</div>
      </div>
      <div class="content">
        <div class="content-title">
          {{ item.title }}
        </div>
        <div class="content-text">
          {{ item.sub_title }}
        </div>
      </div>
    </div>

    <div class="elpagination">
      <div class="elpagination-title">共{{ total }}条</div>
      <el-pagination background layout="prev, pager, next" :total="total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import { getNoticeList } from "@/api/notice";
export default {
  name: "mainpage",
  components: {},
  data() {
    return {
      noticelist: [],
      total: 0,
      icon: require("@/assets/矩形 88.png"),
      timeicon: require("@/assets/时间 钟表.png"),
    };
  },

  created() {
    this.getnoticelist();
  },
  methods: {
    todetail(id) {
      this.$router.push({
        path: "/home/announcement/notifydetails",
        query: {
          id: id,
        },
      });
    },

    getnoticelist(params) {
      this.noticelist = [];
      getNoticeList(params)
        .then((response) => {
          if (response.data.data && response.data.data.length > 0) {
            this.total = response.data.total;
            response.data.data.forEach((item) => {
              this.noticelist.push({
                id: item.id,
                title: item.title,
                sub_title: item.sub_title,
                updated_at: dayjs
                  .unix(item.updated_at)
                  // .format("YYYY-MM-DD HH:mm:ss"),
                  .format("YYYY-MM-DD"),
              });
            });
          }
        })
        .catch((error) => {
        });
    },
  },
};
</script>
<style scoped lang="scss">
.mainpage {
  margin-bottom: 30px;
  // .head-img{
  //   width: 100%;
  //   img{
  //     width: 100%;
  //   }
  // }
  .item {
    min-height: 65px;
    background: #ffffff;
    box-shadow: 0px 1px 0px 0px rgba(223, 223, 223, 0.92);
    padding: 20px;
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    .update-time {
      width: 55px;
      height: 46px;
      box-sizing: border-box;
      background: #ffffff;
      border: 1px solid #ec6941;
      margin-right: 22px;
      div:nth-child(1) {
        height: 22px;
        line-height: 22px;
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #ffffff;
        background: #ec6941;
        text-align: center;
        // border: 1px solid #ec6941;
      }
      div:nth-child(2) {
        height: 22px;
        line-height: 22px;
        text-align: center;
        background: #ffffff;
        font-size: 12px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #ec6941;
      }
    }
    .content {
      flex: 1;
      // height: 100%;
      .content-title {
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #000000;
        user-select: none;
        // white-space: nowrap;
      }
      .content-text {
        margin-top: 24px;
        // margin-left: 17px;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #666666;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        line-clamp: 4;
        -webkit-box-orient: vertical;
      }
    }
  }
  .item + .item {
    border-top: 1px solid #eeeeee;
  }
  .elpagination {
    margin-top: 40px;
    margin-bottom: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    .elpagination-title {
      margin-right: 4px;
    }
  }
}
</style>
